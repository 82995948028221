.headerText {
  color: #252733;
  font-size: 20px;
  font-family: Mulish;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.2px;
  word-wrap: break-word;
}
.title {
  color: #252733;
  font-size: 19px;
  font-family: Mulish;
  font-weight: 700;
  letter-spacing: 0.4px;
  word-wrap: break-word;
}
.data {
  color: #252733;
  font-size: 14px;
  font-family: Mulish;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.2px;
  word-wrap: break-word;
}
.Due {
  color: #ff0000;
  font-size: 30px;
  font-family: Mulish;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.2px;
  word-wrap: break-word;
}
.Total {
  color: #252733;
  font-size: 30px;
  font-family: Mulish;
  font-weight: 600;
  line-height: 20px;
}
.totalTitle {
  color: #9fa2b4;
  font-size: 14px;
  font-family: Mulish;
  font-weight: 700;
  letter-spacing: 0.2px;
  word-wrap: break-word;
  margin: 0.5rem;
}
.headerContainer {
  width: 50%;
  margin: 2rem;
  display: flex;
  justify-content: space-around;
  color: #252733;
  font-size: 30px;
  font-family: Mulish;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.2px;
  word-wrap: break-word;
}
