.digitalClock {
  height: calc(48px * 3);
  flex-shrink: 0;
}
.digitalClock li:hover {
  color: #000 !important;
  border-radius: 10px;
}

.digitalClock li {
  justify-content: center !important;
  color: rgb(143, 143, 143) !important;
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: 110% !important;
}
.BookMore {
  font-size: 16px;
  text-align: center;
}
.mail {
  font-weight: 600;
  text-decoration: underline;
  text-align: center;
}
