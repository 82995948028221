/* index.jsx */
.no-invoice-display {
  @apply w-[100vw] h-[100vh] flex items-center justify-center bg-white;
}

.no-invoice-text {
  @apply text-color-dark text-4xl font-extrabold leading-10;
}

.item-body {
  @apply bg-color-body-default h-full;
}

.item-outer-body {
  @apply relative w-full lg:max-w-[50%] mx-auto min-h-screen flex flex-col;
}

.item-top-section {
  @apply bg-color-light-grey w-full flex justify-between items-center flex-row-reverse pt-5 md:pt-8 pb-5 px-5 h-20;
}

.skeleton-view {
  @apply absolute left-5;
}

.skeleton-inner-view {
  @apply w-40 min-h-10 rounded-xl;
}

.no-item-middle-section {
  @apply w-full relative flex flex-col items-center gap-y-4 pb-4 lg:pb-11 bg-color-light-grey overflow-hidden flex-1 px-5;
}

.bill-view {
  @apply w-full;
}

.bill-outer-view {
  @apply flex justify-between items-center cursor-pointer;
}

.bill-inner-view {
  @apply text-color-dark font-semibold text-2xl md:text-3xl;
}

.guest-view {
  @apply w-full flex flex-col gap-y-3 pt-2;
}

.logo-width {
  @apply w-40;
}

.logo-max-width {
  @apply max-w-40;
}

.guest-inner-view {
  @apply flex items-center gap-2 text-color-dark;
}

.no-item-bottom-section {
  @apply w-full flex flex-col py-5 border-y border-grey-color-shade;
}

.no-item-img-view {
  @apply flex justify-center;
}

.no-item-img {
  @apply w-10;
}

.no-item-header-text {
  @apply text-xl text-color-dark text-center my-2;
}

.no-item-desc-text {
  @apply text-center text-color-dark;
}

.toggle-icon {
  @apply z-20 relative;
}

.loader-fetching-text-view {
  @apply flex justify-center items-center flex-col flex-1 gap-3 text-color-dark bg-white;
}

.loader-fetching-text {
  @apply text-color-dark mt-3;
}

.no-bill-view {
  @apply flex flex-1 bg-white;
}

/* PayingBillInfo.jsx */

.bill-tab-view {
  @apply w-full flex flex-col gap-y-3 mt-0.5;
}

.bill-tab-outer-view {
  @apply mb-4;
}

.bill-paid-text {
  @apply flex flex-row items-center gap-2 text-color-green font-semibold;
}

.remaining-guest-text {
  @apply flex items-center gap-2 text-color-dark;
}

.margin-y {
  @apply my-5;
}

.order-item-view {
  @apply w-full flex flex-col gap-2 overflow-x-hidden bg-white-color-shade p-3 mb-5;
}

.item-inner-view {
  @apply flex justify-between items-center text-color-dark;
}

.font-light-text {
  @apply font-light;
}

.order-quantity-view {
  @apply me-2;
}

.item-expand-view {
  @apply flex justify-between items-center cursor-pointer;
}

.width-max {
  @apply w-full;
}

.order-bottom-view {
  @apply flex justify-between items-center mt-2;
}

.total-text-view {
  @apply flex justify-between items-center border-t border-solid border-grey-color-shade mt-2 pt-2;
}

.total-text-style {
  @apply text-xl text-color-dark font-semibold;
}

.total-digit-style {
  @apply text-xl text-color-dark;
}

.btn-bottom-view {
  @apply flex justify-between items-center gap-4 py-6;
}

.email-form-view {
  @apply w-full flex flex-col;
}

.email-outer-view {
  @apply flex flex-col gap-2 w-full mt-5;
}

.email-inner-view {
  @apply flex flex-col;
}

.alert-text-view {
  @apply text-sm text-left w-full mb-1 mt-1 text-red-color px-4;
}

.margin-top-6 {
  @apply mt-6;
}

.padding-horizontal {
  @apply px-1;
}

.padding-h-4 {
  @apply md:px-4;
}

.or-text-style {
  @apply text-center text-color-dark my-3 text-xl;
}

.card-view {
  @apply w-full grid grid-cols-2 items-center my-3;
}

.card-inner-view {
  @apply col-span-1 flex gap-x-2 align-baseline;
}

.ending-with-text {
  @apply text-color-dark text-xs leading-none;
}

.change-outer-div {
  @apply col-span-1 flex justify-end;
}

.change-inner-div {
  @apply text-white text-sm bg-color-dark cursor-pointer px-2 py-1 rounded-full;
}

.processing-div {
  @apply h-screen fixed inset-0 flex justify-center items-center text-color-dark bg-black bg-opacity-40 gap-2 flex-col;
}

.paid-section {
  @apply w-full p-4 px-0;
}

.reservation-not-found {
  @apply w-[100vw] h-[100vh] flex justify-center items-center;
}

.reservation-not-found-text {
  @apply text-color-light text-4xl font-extrabold;
}

.bill-section-paid {
  @apply w-full relative flex flex-col items-center bg-color-light-grey overflow-hidden pb-4 flex-1 px-5;
}

.bill-detail-view {
  @apply w-full my-2;
}

.item-paid-view {
  @apply flex items-center gap-4;
}

.underline-style {
  @apply text-center text-color-dark underline cursor-pointer mb-4 font-semibold;
}
.some-one-text {
  @apply flex items-center justify-center text-color-dark underline font-semibold;
}
.share-bill-div {
  @apply text-center md:px-4;
}
.share-bill-text {
  @apply text-xl text-color-dark mt-3;
}

.share-bill-desc {
  @apply text-color-dark mt-4;
}

.qr-code-view {
  @apply w-full bg-white rounded-lg mt-3 flex items-center justify-center;
}

.bill-bottom-section {
  @apply w-full flex flex-col justify-end flex-1 text-color-dark;
}

.bill-section-content {
  @apply hidden;
}

.bill-bottom-active .bill-section-content {
  @apply block;
}

.dotted-seperated-line {
  @apply flex justify-between w-full;
}

.showBillInfo {
  @apply border-t border-dashed border-grey-color-shade pt-3 mt-3;
}

.hide-show-text {
  @apply flex text-color-dark cursor-pointer items-center gap-1 mr-3;
}

.bill-section-detail {
  @apply flex justify-between items-center text-sm;
}

.semibold-text {
  @apply font-semibold;
}

.social-view {
  @apply flex justify-center items-center gap-4 border-t border-dashed border-grey-color-shade pt-3 mt-3;
}

.social-icon-view {
  @apply cursor-pointer text-color-dark;
}

.res-location-text {
  @apply my-5 text-center text-sm;
}

.email-text {
  @apply text-center text-sm;
}

.website-text {
  @apply mt-2 text-center text-sm;
}

.vat-no-text {
  @apply mt-4 mb-8 text-center text-sm;
}

.payment-intent-view {
  @apply fixed inset-0 bottom-0 flex justify-center items-center h-screen;
}

/* paymentFormInfo.jsx */

.payment-outer-view {
  @apply w-full relative flex flex-col items-center gap-y-5 pb-1 sm:px-4 lg:px-12 lg:py-8 bg-white;
}

.payment-inner-view {
  @apply w-full flex flex-col mx-5 gap-y-7 items-center;
}

.card-input-view {
  @apply w-full h-12 py-3 bg-color-dark rounded-3xl border justify-center items-center inline-flex;
}

.h-w-full {
  @apply w-full h-full;
}

.card-input-inner-view {
  @apply text-center text-color-light text-xl font-semibold leading-normal;
}

.card-input-outer-view {
  @apply flex w-full gap-3 items-center;
}

.processing-fixed {
  @apply h-screen fixed inset-0 flex justify-center items-center text-color-dark bg-opacity-50 gap-2 flex-col;
}

/* SplitBillInfo.jsx */

.split-main-div {
  @apply w-full lg:max-w-full relative flex flex-col items-center;
}

.count-container-light {
  @apply bg-color-body-default rounded-xl p-5 mt-5 w-full min-h-36;
}

.count-button {
  @apply text-color-light py-2 rounded-3xl w-20 text-center bg-color-dark cursor-pointer text-2xl leading-none;
}

.how-many-text {
  @apply text-color-dark font-normal text-xl;
}

.flex-full-style {
  @apply flex justify-between items-center w-full mt-3;
}

.geust-count-style {
  @apply select-none text-6xl text-color-dark;
}

.plus-minus-div {
  @apply flex basis-16 justify-center items-center gap-x-5 font-bold text-xl;
}

.bill-total-text {
  @apply text-left w-full mt-4 text-color-dark;
}

.split-bill-view {
  @apply flex w-full flex-col justify-between mx-7;
}

.split-bill-inner-view {
  @apply flex w-full justify-between;
}

.split-total-view {
  @apply text-color-dark text-xl font-semibold leading-normal;
}

.split-amount-view {
  @apply text-right text-color-dark text-xl font-semibold leading-normal;
}

.margin-t-b-4 {
  @apply my-4;
}

.split-progress-style {
  @apply h-screen fixed inset-0 flex justify-center items-center text-color-dark bg-black bg-opacity-40 gap-2;
}

.success-payment-div {
  @apply flex flex-col flex-1 gap-3 justify-center items-center text-color-dark;
}

.receipt-sent-view {
  @apply flex flex-row items-center gap-1;
}

/* Accordion */
.custom-accordion {
  @apply w-full;
}

.custom-accordion .accordion__button {
  @apply p-0 bg-transparent flex items-center justify-between flex-row-reverse text-color-dark;
}

.custom-accordion .accordion__button:hover {
  @apply bg-transparent;
}

.custom-accordion .accordion__button::before {
  @apply rotate-45 mr-1 -mt-1;
}

.custom-accordion .accordion__button[aria-expanded="true"]::before {
  @apply rotate-[225deg] mt-1;
}

.custom-accordion .accordion__panel {
  @apply px-0 py-0 text-color-dark;
}

.custom-accordion.no-arrow .accordion__button {
  @apply justify-center underline font-semibold;
}

.custom-accordion.no-arrow .accordion__button::before {
  @apply hidden;
}

.pay-full-view,
.split-bill {
  @apply opacity-0 invisible h-0 transition-all duration-100 delay-100 ease-in-out;
}

.pay-full-active,
.split-bill-active {
  @apply opacity-100 visible h-auto;
}

.custom-textarea {
  @apply w-full border-color-dark text-color-dark bg-transparent border rounded-2xl p-4;
}
