.hover-row:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.table-headers {
  color: #9fa2b4;
  font-size: 14px;
  font-family: Mulish;
  font-weight: 700;
  letter-spacing: 0.2px;
  word-wrap: break-word;
}
.table-data {
  color: #252733;
  font-size: 14px;
  font-family: Mulish;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.2px;
  word-wrap: break-word;
}
