.accordionContainer::before {
  @apply bg-transparent;
}

.policyChecksContainer {
  @apply flex justify-center flex-col my-5;
}

.skeleton-gray {
  @apply h-32 rounded-lg w-11/12 mx-auto mb-10 absolute left-0 right-0;
}

.no-resturant-outer-view {
  @apply w-screen h-screen flex justify-center items-center bg-white;
}

.no-resturant-view {
  @apply text-color-dark text-4xl font-extrabold;
}

.booking-main-div {
  @apply bg-color-body-default text-color-dark overflow-x-hidden overscroll-none min-h-screen;
}

.booking-header-section {
  @apply relative w-full lg:max-w-[50%] mx-auto min-h-screen flex flex-col bg-white;
}

.booking-header-inner {
  @apply bg-color-light-grey w-full flex justify-center items-center pt-5 md:pt-8 pb-5 px-5 h-20;
}

.back-icon-style {
  @apply absolute left-4;
}

.skeleton-style {
  @apply w-40 min-h-10 rounded-xl self-center absolute top-6;
}

.logo-width {
  @apply max-w-40;
}

.skeleton-main {
  @apply h-56 rounded-lg top-0 left-0 mx-10;
}

/* Availability Info */

.available-main-div {
  @apply flex flex-col pt-1 w-full;
}

.available-container {
  @apply w-full text-color-dark text-xl font-semibold leading-normal pt-2;
}

.available-time-style {
  @apply grid grid-cols-3 gap-y-3 gap-x-3 py-5;
}

.available-time-view {
  @apply py-3.5 font-semibold rounded-lg flex justify-center items-center cursor-pointer hover:bg-color-dark hover:text-white;
}

.no-time-view {
  @apply text-lg mt-2.5;
}

.waiting-list-style {
  @apply text-lg mt-2.5 text-left underline block;
}

.middle-container {
  @apply flex-1 flex flex-col bg-color-light-grey items-center px-5 pb-10;
}

.middle-skeleton-view {
  @apply pb-40 pt-6;
}

.bottom-section-view {
  @apply w-full py-3 px-1 border-y border-grey-color-shade;
}

.bottom-text-style {
  @apply text-color-dark font-semibold text-lg;
}

/* Confirm Info */

.confirm-container {
  @apply flex flex-col mx-5 gap-y-3 items-center;
}

.confirm-top-section {
  @apply flex justify-between items-center w-full border-y py-3;
}

.guest-text-style {
  @apply text-color-dark text-lg font-semibold leading-tight;
}

.confirm-view {
  @apply w-full flex flex-col gap-y-3;
}

.res-cnf-style {
  @apply text-color-dark text-xl font-semibold leading-normal;
}

.res-desc-style {
  @apply text-color-dark font-normal;
}

.res-cnf-bottom {
  @apply w-full flex flex-col gap-y-1 border-b pb-5 pt-3;
}

.res-cnf-b-text {
  @apply text-color-dark text-lg font-semibold leading-tight;
}

.res-cnf-card {
  @apply text-color-dark text-sm font-semibold leading-tight flex items-center gap-x-2;
}

.copy-link-outer {
  @apply bg-color-dark rounded-3xl text-center text-white text-lg font-semibold leading-tight w-full h-12 my-5;
}

.copy-link-inner {
  @apply flex items-center gap-2 justify-center;
}

.confirm-bottom-sec {
  @apply border-b border-dashed w-full mb-3 border-grey-color-shade;
}

.cnf-bottom-title {
  @apply text-color-dark font-semibold;
}

.cnf-bottom-div {
  @apply flex flex-wrap gap-x-3 gap-y-4 pt-4;
}

.bottom-option {
  @apply cursor-pointer h-9 px-7 py-2 rounded-3xl justify-center items-center gap-5 inline-flex;
}

.bottom-option-text {
  @apply text-center font-medium;
}

.bottom-textarea {
  @apply w-full h-20 rounded-lg border border-grey-color-shade resize-none p-2 mt-4;
}

.border-style {
  @apply border-b border-dashed w-full mt-3 border-grey-color-shade;
}

.bottom-btn {
  @apply mt-5 mb-8 items-center rounded-3xl text-center text-lg font-semibold leading-tight w-full justify-center flex h-12 text-white;
}

.bottom-btn-inner {
  @apply flex items-center gap-2;
}

/* calender-info */

.calender-div {
  @apply h-full flex flex-col gap-y-7 items-center pt-5 bg-color-light-grey p-5 flex-1;
}

.calender-top-section {
  @apply flex w-full justify-between mx-7;
}

.guest-cale {
  @apply text-color-dark text-xl font-semibold leading-normal;
}

.guest-button-div {
  @apply flex items-center gap-x-5 font-bold text-xl;
}

.guest-btn-text {
  @apply cursor-pointer w-6 text-center select-none;
}

.calender-seperator {
  @apply border-b w-full;
}

.clock-view {
  @apply w-full gap-4 grid grid-cols-3;
}

.clock-time {
  @apply py-3 rounded font-medium justify-center items-center flex cursor-pointer text-xl hover:bg-color-dark hover:text-white;
}

.search-btn-div {
  @apply w-full h-12 justify-center items-center inline-flex;
}

.color-center-style {
  @apply text-center text-color-dark;
}

.underline-text {
  @apply underline font-semibold;
}

/* information-info */

.info-main-div {
  @apply bg-color-light-grey flex flex-col p-5 gap-y-7 items-center overflow-x-hidden;
}

.info-top-sec {
  @apply flex justify-between items-center w-full;
}

.info-guest-text {
  @apply text-color-dark text-lg font-semibold leading-tight;
}

.info-middle-sec {
  @apply w-full border-y pt-4 pb-8 border-grey-color-shade;
}

.info-middle-text {
  @apply text-color-dark text-xl font-semibold leading-normal;
}

.info-inner-div {
  @apply mt-4 flex flex-col gap-4;
}

.info-input-style {
  @apply rounded-md border-slate-300 bg-transparent text-color-dark py-3 px-4 border w-full h-12;
}

.error-text {
  @apply my-1 text-red-color block;
}

.country-view {
  @apply flex gap-3;
}

.credit-card-desc {
  @apply text-color-dark text-sm font-normal leading-none;
}

.terms-condition-text {
  @apply text-color-dark text-xs font-bold underline leading-none;
}

.info-bottom-view {
  @apply rounded-lg bg-color-dark flex flex-col justify-center p-8;
}

.bottom-first-sec {
  @apply mx-auto text-white text-center;
}

.info-intro-text {
  @apply text-lg mt-5 font-semibold;
}

.learn-more-text {
  @apply w-auto mx-auto mt-8 px-6 leading-none h-11 font-semibold;
}

.payment-div {
  @apply border-y my-6 py-3;
}

.payment-inner-div {
  @apply flex gap-2 items-center w-full;
}

.payment-element-div {
  @apply mt-4 mb-1;
}

.check-box-style {
  @apply relative flex mb-3 items-center gap-2;
}

.policy-text {
  @apply text-color-dark text-xs font-medium leading-none cursor-pointer select-none;
}

.agree-error-style {
  @apply my-0.5 text-sm text-red-500 relative -top-2 left-2;
}

.by-clicking-style {
  @apply text-color-dark text-xs font-medium leading-none;
}

.info-bottom-btn {
  @apply w-full mt-5 mb-3 justify-center items-center inline-flex;
}
