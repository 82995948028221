.sideBarElement {
  color: var(--backgrounds-dark-theme, #1e1f23);
  /* Regular/16px */
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
  height: 56px;
}

.sideBarIcon {
  color: #a5a5a7;
  margin-left: 32px;
  margin-right: 24px;
}

.sideBarButton {
  @apply flex items-center p-2 hover:bg-[#F5F5F5] dark:hover:bg-gray-700 w-full;
}
