.bigContainer {
  width: 100%;
  height: 100%;
  z-index: 1150;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: #00000087;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 19px;
  background: #fff;
  padding: 24px;
  gap: 10px;
}

.header {
  color: var(--primary-900-p, #0b172c);
  text-align: center;

  /* Headline 4 */
  font-family: Figtree;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 24.2px */
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.content > p {
  color: var(--primary-900-p, #0b172c);
  text-align: center;
  font-family: Figtree;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 15.6px */
}

.btnPay {
  display: flex;
  width: 100%;
  max-width: 212px;
  height: 38px;
  padding: 9px 44px 9px 43px;
  justify-content: center;
  align-items: center;
  border-radius: 19.024px;
  border: 1px solid var(--primary-900-p, #0b172c);
  background: var(--primary-900-p, #0b172c);
}

.btnPay > p {
  color: var(--primary-700-p, #f7fde3);
  text-align: center;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Figtree;
  font-size: 12.683px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.024px; /* 150% */
}

.btnCancel {
  display: flex;
  width: 100%;
  max-width: 212px;
  height: 38px;
  padding: 9.013px 62px 9.013px 61px;
  justify-content: center;
  align-items: center;
  border-radius: 19.024px;
  border: 1px solid var(--primary-900-p, #0b172c);
}

.btnCancel > p {
  color: var(--primary-900-p, #0b172c);
  text-align: center;
  font-feature-settings:
    "clig" off,
    "liga" off;
  font-family: Figtree;
  font-size: 12.683px;
  font-style: normal;
  font-weight: 600;
  line-height: 19.024px; /* 150% */
}
