#button-background {
  position: relative;
  background: linear-gradient(
    90deg,
    rgba(42, 43, 73, 0.8) 0%,
    rgba(42, 43, 73, 0.2) 100%
  ) !important;
  width: 100%;
  height: 50px;
  border: white;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
}

#slider {
  transition:
    width 0.3s,
    border-radius 0.3s,
    height 0.3s;
  position: absolute;
  left: 5px;
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

#slider.unlocked {
  transition: all 0.3s;
  width: inherit;
  left: 0 !important;
  height: inherit;
  border-radius: inherit;
}

.material-icons {
  color: #000;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

.slide-text {
  color: #fff;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default;
}

.bottom {
  position: fixed;
  bottom: 0;
  font-size: 14px;
  color: white;
}

.bottom a {
  color: white;
}

#loading-button {
  background-color: #fff;
}
