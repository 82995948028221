/* guest reservation */
/* index */
.guest-no-record-found-div {
  @apply w-screen h-screen flex justify-center items-center bg-white;
}

.guest-no-record-text {
  @apply text-color-dark text-4xl font-extrabold;
}

.guest-main-div {
  @apply bg-color-body-default h-full;
}

.guest-top-section {
  @apply relative w-full lg:max-w-[50%] mx-auto min-h-screen flex flex-col;
}

.guest-top-inner-section {
  @apply bg-white w-full flex justify-center items-center pt-5 md:pt-8 pb-5 px-5 h-20;
}

.guest-logo-skeleton {
  @apply w-40 min-h-10 rounded-xl;
}

.guest-middle-section {
  @apply flex flex-1 bg-white;
}

.guest-skeleton-main {
  @apply w-full mx-5 my-2;
}

.guest-skeleton-inner {
  @apply h-48 rounded-lg;
}

/* information & confirm */

.guest-information-main {
  @apply flex flex-col px-5;
}

.guest-confirm-main {
  @apply flex flex-col mx-5 w-full;
}

.invited-div {
  @apply w-full mt-3 text-color-dark text-2xl font-semibold leading-normal;
}

.guest-info-desc-view {
  @apply w-full my-4 text-color-dark text-sm;
}

.guest-info-middile-sec {
  @apply w-full flex flex-col gap-y-2;
}

.guest-confirm-middle {
  @apply w-full flex flex-col gap-y-2 mt-4 mb-1;
}

.guest-info-middle-text {
  @apply text-color-dark text-lg font-semibold leading-tight;
}

.guest-your-info-div {
  @apply text-color-dark text-xl font-semibold leading-normal my-2;
}

.guest-form-div {
  @apply flex flex-col gap-4 mb-10;
}

.guest-form-alert-style {
  @apply my-1 text-red-color;
}

.guest-phone-view {
  @apply w-full flex gap-3 text-color-dark;
}

.guest-form-button {
  @apply my-8 items-center rounded-3xl text-center text-lg font-semibold leading-tight w-full justify-center flex h-12 text-white bg-color-dark;
}

.guest-form-button-inner {
  @apply flex items-center gap-2;
}

.whos-coming {
  @apply text-color-dark font-semibold my-4;
}

.guest-list-view {
  @apply flex flex-row items-center gap-3 mb-3;
}

.guest-name-view {
  @apply bg-color-body-default h-8 w-8 rounded-full text-color-dark items-center justify-center flex capitalize;
}

.guest-name-inner {
  @apply flex text-color-dark flex-col;
}

.guest-main-name {
  @apply font-medium leading-none pb-0.5;
}

.guest-organizer {
  @apply text-xs leading-none;
}

.preference-div {
  @apply text-color-dark text-xl font-semibold my-4;
}

.guest-accordion-div {
  @apply w-full flex flex-col items-start;
}

.border-res-line {
  @apply border-b w-full mt-3;
}
