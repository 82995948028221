.custom-accordion .accordion__button {
  @apply py-0;
}

.custom-accordion .accordion__panel {
  @apply mb-5;
}

.skeleton-view {
  @apply w-40 min-h-10 rounded-xl;
}

.faq-outer-view {
  @apply bg-color-body-default flex justify-center;
}

.faq-inner-view {
  @apply w-full min-h-[calc(100vh-80px)] relative flex flex-col items-center bg-white;
}

.faq-outer-view .accordion__button {
  @apply py-3;
}

.toggle-btn-view {
  @apply bg-white w-full flex justify-between items-center flex-row-reverse pt-5 md:pt-8 pb-5 px-5;
}

.toggle-btn {
  @apply z-20 relative;
}

.logo-skeleton-view {
  @apply absolute top-6 left-0;
}

.logo-img-view {
  @apply max-w-40;
}

.faq-main-view {
  @apply px-6 w-full pb-4;
}

.faq-title-style {
  @apply text-color-dark font-semibold;
}

.toc-title-style {
  @apply w-full text-color-dark text-2xl lg:text-3xl font-semibold mb-5 leading-none;
}

.toc-inner-title {
  @apply text-color-dark text-xl font-semibold mb-4;
}
