.choice:hover,
.choice:hover div {
  background: var(--primary-900-p, #0b172c);
  color: var(--primary-600-p, #fcfff1) !important;
}
.dates {
  color: #000;

  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
}
