@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  /* @apply bg-custom-blue; */
}

#booking {
  @apply bg-white text-black;
  @apply pb-[14px];
  @apply overflow-x-hidden;
}

#dark_layout {
  @apply bg-[#17243A] text-amber-50;
  /* @apply py-[14px] px-[10px]; */
  @apply relative min-h-[100vh];
  @apply h-full flex flex-col items-center;
}

html,
body,
#root,
.App,
#booking {
  height: 100%;
  overscroll-behavior: none;
}

.btn-dark-default {
  @apply bg-color-dark text-color-light rounded-full p-2 md:p-3 flex items-center justify-center cursor-pointer gap-2 w-full font-semibold text-lg;
}

.btn-light-border {
  @apply bg-white text-color-dark border-color-dark border rounded-full p-2 md:p-3 flex items-center justify-center cursor-pointer gap-1 w-full font-semibold text-lg;
}

.btn-white-default {
  @apply bg-white text-color-dark rounded-full p-2 md:p-3 flex items-center justify-center cursor-pointer gap-1 w-full text-lg;
}

.btn-green-default {
  @apply bg-color-green text-color-light rounded-full p-2 md:p-3 flex items-center justify-center cursor-pointer gap-1 w-full font-semibold text-lg;
}

.custom-form-control {
  @apply bg-transparent text-color-dark rounded-full py-3 px-4 border border-color-dark w-full;
}

.flex-center {
  @apply flex items-center justify-center;
}

.flex-center-between {
  @apply flex items-center justify-between;
}

.App {
  text-align: center;
  background-color: #0b172c;
  font-family: "Figtree", sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1a1944;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#booking {
  min-height: 100vh;
}

@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.Mui-disabled.MuiPickersDay-root {
  text-decoration: line-through;
}

.css-1kvln7l-MuiButtonBase-root-MuiMenuItem-root-MuiDigitalClock-item.Mui-selected {
  border-radius: 10px;
  background-color: #e5e7ed !important;
  color: black !important;
}

.MuiAccordionDetails-root.css-15v22id-MuiAccordionDetails-root {
  padding: auto 0px !important;
}

/* .StripeElement {
  @apply rounded-lg border border-slate-400 h-[50px] p-[16px] w-full hover:outline-[#a1c3de] bg-white;
} */

.loader {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}

.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 3px solid #fff;
  animation: prixClipFix 2s linear infinite;
  @apply dark:border-slate-800;
}

.loader-white {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}

.loader-white::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 3px solid #fff;
  animation: prixClipFix 2s linear infinite;
  @apply dark:border-slate-50;
  border-color: #fff;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }

  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }

  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.hideBorder div fieldset {
  border-color: transparent !important;
}

.amex-button {
  background: linear-gradient(
    90deg,
    rgba(154, 170, 255, 0.8) 0%,
    rgba(154, 170, 255, 0.2) 100%
  ) !important;
}

.count-container {
  background: linear-gradient(180deg, #212d43 0%, #283852 100%);
  border-radius: 12px;
  padding: 20px;
  margin-top: 20px;
  width: 100%;
  height: 142px !important;
  /* margin-bottom: 20px; */
}

.problem-text-area {
  background-color: transparent;
  border: 1px solid #eee;
  border-radius: 16px;
  margin-top: 25px;
  padding: 15px;
  font-size: 14px;
  color: #fff;
}

.email-form input:focus {
  border: none;
  outline: none;
}

.email-form input {
  border: none;
  outline: none;
}

.email-form {
  border: 1px solid #e5e7ed;
  border-radius: 45px;
  padding: 2px;
}

.default-card-check {
  font-size: 35px !important;
  background-color: #9aaaff;
  color: #212d43;
  border-radius: 50%;
  margin-right: 15px;
  margin-bottom: 10px;
  padding: 3px;
}

.container {
  background: #fff;
  padding: 15px;
  width: 400px;
}

.header {
  text-align: center;
  color: #000;
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
}

.bill-info {
  color: #9aaaff;
  font-weight: 600;
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 20px;
}

.paid-bill {
  color: #000;
  background: #9aaaff66;
  padding: 5px 15px;
  border-radius: 20px;
}

.details {
  display: flex;
  align-items: center;
  color: #000;
}

.details-item {
  margin-bottom: 4px;
}

.detail {
  display: flex;
  align-items: center;
  gap: 5px;
}

.item-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000;
}

.separator {
  margin-top: 3px;
  margin-bottom: 3px;
}

.item-name {
  font-weight: 300;
}

.item-price {
  margin-left: 0.5em;
}

.total {
  width: 100%;
  color: #000;
  margin-top: 5px;
}

.total-details {
  margin-top: 5px;
}

.total-text {
  font-size: 18px;
  color: #9aaaff;
  font-weight: 600;
}

.total-amount {
  font-size: 20px;
  color: #9aaaff;
}

.total-bottom {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.wide-separator {
  margin-top: 25px;
  margin-bottom: 25px;
}

.powered-by {
  text-align: center;
  color: #9aaaff;
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
}

.server {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #000;
  margin-bottom: 3px;
}

* {
  -webkit-font-smoothing: antialiased;
}

.title32px {
  color: #2a2b49;
  font-size: 32px;
  font-weight: 600;
}

.title24px {
  color: #2a2b49;
  font-size: 24px;
  font-weight: 600;
}

@media (max-width: 767px) {
  .title32px {
    font-size: 20px;
  }

  .title24px {
    font-size: 18px;
  }
}
