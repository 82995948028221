.no-reservation-view {
  @apply w-[100vw] h-[100vh] flex justify-center items-center bg-color-light;
}

.no-reservation-text {
  @apply text-color-dark text-4xl font-extrabold leading-10;
}

.add-payment-view {
  @apply w-full lg:max-w-[50%] relative flex flex-col items-center mx-auto bg-color-light-grey;
}

.width-max {
  @apply w-full;
}

.select-text {
  @apply w-full text-color-dark text-xl font-semibold leading-normal text-center mb-5;
}

.list-payment-method {
  @apply flex flex-col gap-y-3 w-full;
}

.list-payment-inner-view {
  @apply flex justify-between items-center mb-4;
}

.card-detail-view {
  @apply gap-x-2;
}

.card-number-style {
  @apply text-color-dark text-sm font-normal leading-none;
}

.card-exp {
  @apply text-color-dark text-xs font-normal;
}

.delete-view {
  @apply flex items-center gap-4 -mt-1;
}

.confirm-style {
  @apply text-red-color-shade text-sm bg-red-color-dark-shade py-1 px-2 rounded-full flex items-center font-semibold gap-1;
}

.seperator-line {
  @apply flex justify-center;
}

.add-payment-text {
  @apply text-color-dark cursor-pointer text-lg font-semibold my-4 text-center underline;
}

.payment-element {
  @apply mt-3 w-full;
}

.payment-outer-element {
  @apply w-full flex flex-col items-center;
}

.payment-inner-element {
  @apply w-full flex flex-col gap-3 bg-color-dark p-8 rounded-2xl;
}

.save-btn-style {
  @apply mt-4 mb-10;
}

.is-modifying-btn {
  @apply flex justify-between w-full my-5 gap-4;
}
