.sidebarMenu {
  box-shadow:
    -425px 0px 119px 0px rgba(0, 0, 0, 0),
    -272px 0px 109px 0px rgba(0, 0, 0, 0.01),
    -153px 0px 92px 0px rgba(0, 0, 0, 0.03),
    -68px 0px 68px 0px rgba(0, 0, 0, 0.04),
    -17px 0px 37px 0px rgba(0, 0, 0, 0.05);
  padding-top: 70px;
  padding-left: 24px;
  padding-right: 16px;
  padding-bottom: 35px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.swipeAbleDrawer {
  border-radius: 32px 32px 0 0;
  padding: 30px;
}

.side-menu-div {
  @apply bg-grey-rgba h-full absolute right-0 top-0 w-full z-10;
}

.side-menu-outer-view {
  @apply w-3/4 bg-white absolute right-0 top-0 h-screen;
}

.side-menu-inner-view {
  @apply flex-1 overflow-y-auto w-full;
}

.side-menu-text-div {
  @apply w-full flex items-center justify-between cursor-pointer py-3 z-20;
}

.side-menu-text {
  @apply text-color-dark text-xl lg:text-2xl font-semibold flex items-center;
}

.side-menu-bottom-view {
  @apply flex items-center gap-4 pt-4;
}

.faq-contact-div {
  @apply pt-2 w-full px-6 flex flex-1 flex-col justify-end;
}

.faq-zumi-text {
  @apply text-grey-color-shade border-t border-dashed border-grey-color-shade py-4;
}

.faq-zumi-desc {
  @apply text-color-dark text-sm md:text-lg text-center;
}

.faq-contact-btn {
  @apply my-6 inline-flex justify-center;
}

.faq-contact-btn-text {
  @apply px-12 py-3 bg-color-dark rounded-3xl text-center;
}
