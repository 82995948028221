.infoContainer {
  height: 400px;
  border-radius: 11px;
  background: #2f3859;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 22px;
}

.infoSec1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 24px;
  overflow: hidden;
  /* background: var(--primary-900-p, #2d4f8a); */
  border-radius: 11px 11px 0 0;
  position: relative;
}

.introduceText {
  width: 300px;
  margin-top: 20px;
}

.creditCardTitle {
  font-weight: 600 !important;
}

.infoSec1 > p:first-child {
  color: #f00;
  text-align: center;

  /* Label */

  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%; /* 13.2px */
  z-index: 1;
}

.infoSec1 > p:last-child {
  color: #fff;

  /* Subhead 1 */

  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;
  max-width: 279px;
  text-align: center;
  z-index: 1;
}

.infoSec2 {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 24px;
}

.infoSec2 > p:first-child {
  color: #fcfff1;
  text-align: center;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
}

.infoSec2 > p:last-child {
  color: var(--primary-900-p, #9aaaff);

  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  text-decoration-line: underline;
}

.payBtnContainer > div {
  border: none !important;
}

.accordionContainer::before {
  background: transparent !important;
}

.comingSoonButton {
  border-radius: 17.659px;
  background: var(--primary-700-p, #f7fde3);
  color: var(--primary-900-p, #0b172c);
  text-align: center;
  font-feature-settings:
    "clig" off,
    "liga" off;

  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 17.659px;
  display: flex;
  width: 161.875px;
  margin-top: 25px;
}

.policyChecksContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 25px;
  margin-right: 50px;
}
