.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* This will center the content vertically on the page */
}

.circularProgress {
  width: 150px !important;
  height: 150px !important;
}
